<template>
  <Loading v-show="showLoading"></Loading>
  <router-view class="main"> </router-view>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import Loading from "@/components/loading.vue";
export default {
  name: "App",
  components: {
    Loading,
  },
  setup() {
    const store = useStore();
    const showLoading = computed(() => {
      return store.getters.showLoading;
    });
    return {
      showLoading,
    };
  },
};
</script>

<style>
body,
html {
  width: 100%;
  height: 100%;
}
#app {
  width: 100%;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
