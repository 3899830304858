import { createStore } from 'vuex'


import getters from './getters'
import loading from './modules/loading'
import common from './modules/common'


export default createStore({
  modules: {
    loading,
    common
  },
  getters
})
