//state
const state = {
    homeIndex: 0  
}

//action
const actions = {
    
}

//mutations
const mutations = {
    HOMEINDEX: (state, data) => {
        state.homeIndex = data;
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}