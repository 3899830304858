<template>
  <div class="loading">
    <div class="item">请稍候，正在努力为您加载</div>
  </div>
</template>

<script>
</script>

<style scoped>
.loading {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(245, 245, 245, 0.23);
  display: flex;
  display: -webkit-flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  color: #999;
  z-index: 9999;
}
.item {
  text-align: center;
}
.loading img {
  width: 70px;
  height: 70px;
}
</style>