import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './route'
// import global from './global'
import './assets/scss/global.scss'
//中英文切换
import i18n from './language'
// import lang from 'element-plus/lib/locale/lang/zh-cn'
// import locale from 'element-plus/lib/locale'


import { 
  Button, 
  Popup,
  List, 
  Row,
  Col,
  PullRefresh, 
  Cell,
  CellGroup,
  NavBar,
  Icon,
  Tag,
  Tab,
  Tabs,
  Toast,
  Area,
  Field,
  Picker,
  Form,
  Search,
  Swipe, 
  SwipeItem,
  Checkbox, 
  CheckboxGroup
} from 'vant';

import lazyPlugin from 'vue3-lazy'

router.afterEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }else{
    document.title = '礼帽出行官方-体面出行坐礼帽,每一程都是风景'
  }
  // window.scrollTo(0, 0);
  next()
});

// // 设置语言
// locale.use(lang)
import {
  ElCheckbox,
  ElContainer,
  ElHeader,
  ElMain,
  ElButton,
  ElInput,
  ElMessage,
  ElMessageBox,
  ElLoading,
  ElSelect,
  ElOption,
  ElDialog,
  ElForm,
  ElFormItem,
  ElCol,
  ElRow,
  ElImage,
  ElCarousel,
  ElCarouselItem
} from 'element-plus';

(async () => {
  const plugins = [
    ElMessage,
    ElMessageBox
  ]
  const components = [
    ElCheckbox,
    ElButton,
    ElContainer,
    ElHeader,
    ElMain,
    ElInput,
    ElLoading,
    ElSelect,
    ElOption,
    ElDialog,
    ElForm,
    ElFormItem,
    ElCol,
    ElRow,
    ElImage,
    ElCarousel,
    ElCarouselItem
  ]
  

  const app = createApp(App)
  app.use(Button);
  app.use(Popup);
  app.use(Cell);
  app.use(CellGroup);
  app.use(List);
  app.use(Row); 
  app.use(Col);
  app.use(PullRefresh);
  app.use(NavBar);
  app.use(Icon);
  app.use(Tag);
  app.use(Tab);
  app.use(Tabs);
  app.use(Area);
  app.use(Toast);
  app.use(Field);
  app.use(Picker);
  app.use(Form);
  app.use(Search);
  app.use(Swipe);
  app.use(SwipeItem);
  app.use(Checkbox);
  app.use(CheckboxGroup);
  app.use(lazyPlugin, {
    loading: require('./assets/images/default.png'), // 图片加载时默认图片
    // error: require('@/assets/images/error.png')// 图片加载失败时默认图片
  })

  components.forEach(component => {
    app.component(component.name, component)
  })
  plugins.forEach(plugin => {
    app.use(plugin)
  })
  app.use(store).use(router).use(i18n).mount('#app')
})()
