import md5 from "./md5.min";
const signKey = '010b05b0d83e4adeb3bbd0e01ca049c4c06dd63096ac44dc88b7a6988a3ae0ca'; // 签名的key
const TIME_SECOND =  1000; //秒
const TIME_MINUTE = 60000; //分钟
const TIME_HOUR = 3600000; //小时
const TIME_DAY = 86400000; //天

export function formatNumber (n) {
  const str = n.toString();
  return str[1] ? str : `0${str}`
}

export function formatTime (date, flag) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  const t1 = [year, month, day].map(formatNumber).join('-');
  const t2 = [hour, minute, second].map(formatNumber).join(':');

  return !flag ? `${t1} ${t2}` : `${t1}`
}

//获取 带前缀(今天/明天/后天) 的时间
export function formatPrefixTime (millisecond) {
  if(isNaN(millisecond) || millisecond <= 0) return '';
  const now = new Date().getTime();
  //获取当天00:00
  const wee = parseInt(now / TIME_DAY) * TIME_DAY - 8 * TIME_HOUR;
  //获取millisecond对应的时间
  const date = new Date(millisecond);
  //判断"今天/明天/后天"及其它时间
  if(millisecond >= wee + TIME_DAY * 3) {
    return formatBriefTime(date);
  } else if(millisecond >= wee + TIME_DAY * 2) {
    return '后天 ' + formatHourAndMinute(date);
  } else if(millisecond >= wee + TIME_DAY) {
    return '明天 ' + formatHourAndMinute(date);
  } else if(millisecond >= wee) {
    return '今天 ' + formatHourAndMinute(date);
  } else {
    return formatBriefTime(date);
  }
}
/**
 * 传入时间后几天
 * @param：传入时间：dates:"2018-04-02", later:往后多少天
 * @returns
 */
export function dateLater(dates, later = 0) {
  let dateObj = {};
  let show_day = new Array('周日', '周一', '周二', '周三', '周四', '周五', '周六');
  let date = new Date(dates);
  date.setDate(date.getDate() + later);
  let day = date.getDay();
  dateObj.year = date.getFullYear();
  dateObj.month = ((date.getMonth() + 1) < 10 ? ("0" + (date.getMonth() + 1)) : date.getMonth()+1);
  dateObj.day = (date.getDate() < 10 ? ("0" + date.getDate()) : date.getDate());
  dateObj.week = show_day[day];
  return dateObj;
}

/**
 * 返回动态时间 今天、昨天、其他时间
 * @param {*} millisecond 毫秒时间戳
 * @returns
 */
export function formatDynamicTime(millisecond) {
  if(isNaN(millisecond) || millisecond <= 0) return '';
  //获取当天00:00
  let wee = new Date().setHours(0, 0, 0, 0)
  //获取millisecond对应的时间
  const date = new Date(millisecond);

  // 判断今天、昨天、其他时间
  if(millisecond > wee) {
    return formatHourAndMinute(date);
  }else if(millisecond > (wee - TIME_DAY)){
    return '昨天 ' + formatHourAndMinute(date);
  }else {
    return formatFullTime(date);
  }
}

/**
 * 返回时间 格式：14:12
 * @param {*} date 时间点：new Date()
 * @returns
 */
export function formatHourAndMinute (date) {
  const hour = date.getHours();
  const minute = date.getMinutes();

  return `${addZero(hour)}:${addZero(minute)}`
}

/**
 * 返回时间 格式：10月10日 14:12
 * @param {*} date 时间点：new Date()
 * @returns
 */
export function formatBriefTime (date) {
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const hour = date.getHours();
  const minute = date.getMinutes();

  return `${addZero(month)}月${addZero(day)}日 ${addZero(hour)}:${addZero(minute)}`
}

/**
 * 返回时间 格式：2021-10-10 14:12
 * @param {*} date 时间点：new Date()
 * @returns
 */
export function formatFullTime(date) {
  const year  = date.getFullYear()
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const hour = date.getHours();
  const minute = date.getMinutes();

  return `${year}-${addZero(month)}-${addZero(day)} ${addZero(hour)}:${addZero(minute)}`
}

function addZero(num) {
  return num < 10 ? ('0' + num) : (num || '00');
}

export function formatBriefDate (date) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return [addZero(year), addZero(month), addZero(day)].map(formatNumber).join('-');
}

export function formatMinSec(millisecond) { //传入的millisecond，单位为ms
  if(isNaN(millisecond)) return '';
  const minutes = parseInt(millisecond  / TIME_MINUTE);
  const seconds = parseInt(millisecond %  TIME_MINUTE / TIME_SECOND);

  return `${addZero(minutes)}:${addZero(seconds)}`;
}

export function formatHourMinSec(millisecond) { //传入的millisecond，单位为ms
  if(isNaN(millisecond)) return '';
  const hours = parseInt(millisecond  / TIME_HOUR);
  const minutes = parseInt(millisecond %  TIME_HOUR / TIME_MINUTE);
  const seconds = parseInt(millisecond %  TIME_MINUTE / TIME_SECOND);

  return hours > 0
    ? `${hours}:${addZero(minutes)}:${addZero(seconds)}`
    : `${addZero(minutes)}:${addZero(seconds)}`;
}

export function formatDay (date) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const t1 = [year, month, day].map(formatNumber).join('-');

  return `${t1}`
}

// 向后加一个时间段 加一天times = 86400, 加一小时 times = 3600;
export function addDate(time,times) {
  var timestamp = Date.parse(new Date(time));
  timestamp = timestamp / 1000;
  timestamp += times;
  return new Date(timestamp * 1000);
}

// 获取签名 sign
export function getSign(data){
  let _data = objKeySort(data);
  let psData = getParamsUrl(_data) + '&key=' + signKey;
  const sign = md5(psData).toUpperCase();
  // console.log('psData:',psData);
  // console.log('sign:',sign);
  return sign;

}

// 排序格式化参数  key=value
export function objKeySort(obj) {
  let newkey = Object.keys(obj).sort();
  let newObj = {};
  for (let i = 0; i < newkey.length; i++) {
    newObj[newkey[i]] = obj[newkey[i]];
  }
  return newObj;
}

// 格式化参数  key=123&
export function getParamsUrl(data) {
  let params = '';
  Object.keys(data).map(item => {
    if(String(data[item]))
      params = params + item + '=' + data[item] + '&';
  });
  
  return params.substr(0,params.length -1);
}



export default {
  filterUndefined(data = {}){
    let newData = {};
    Object.keys(data).map(item=>{
      if(data[item] != null && data[item] != undefined){
        newData[item] = '';
      }else{
        newData[item] = data[item];
      }
    });
    return newData
  },
  navigateTo(url){
    wx.navigateTo({
      url
    })
  },


  deepCopy (data) {
    return JSON.parse(JSON.stringify(data));
  },

  // 字符串中间插入 \n  换行符 默认第6个位置插入
  serializeAddr(str,index){
    if(typeof str !== String || !str) return []
    let text = str.split('');
    text.splice(index || 6,0,'\n');
    return text.join('');
  },

  // 计算字符串长度
  getByteLen(val) {
    var len = 0;
    for (var i = 0; i < val.length; i++) {
      var a = val.charAt(i);
      if (a.match(/[^\x00-\xff]/ig) != null)
      {
        len += 2;
      }
      else
      {
        len += 1;
      }
    }
    return len;
  },
  formatDay (date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const t1 = [year, month, day].map(formatNumber).join('-');

    return `${t1}`
  }
}

//获得角度的函数
export function getAngle(fromLng, fromLat, toLng, toLat){
  var ret
  var w1 = fromLat/180 * Math.PI
  var j1 = fromLng/180 * Math.PI

  var w2 = toLat/180 * Math.PI
  var j2 = toLng/180 * Math.PI

  ret = 4 * Math.pow(Math.sin((w1 - w2) / 2), 2) - Math.pow(Math.sin((j1 - j2) / 2) * (Math.cos(w1) - Math.cos(w2)), 2);
  ret = Math.sqrt(ret);

  // var temp = Math.sin(Math.abs(j1 - j2) / 2) * (Math.cos(w1) + Math.cos(w2));
  var temp = Math.sin((j1 - j2) / 2) * (Math.cos(w1) + Math.cos(w2));
  // console.log(temp)
  ret = ret/temp;

  ret = Math.atan(ret) / Math.PI * 180 ;
  ret += 90;

  // 这里用如此臃肿的if..else是为了判定追踪单个点的具体情况,从而调整ret的值
  if(j1-j2 < 0){
    // console.log('j1<j2')
    if(w1-w2 < 0){
      // console.log('w1<w2')
      ret;
    }else{
      // console.log('w1>w2')
      ret = -ret+180;
    }
  }else{
    // console.log('j1>j2')
    if(w1-w2 < 0){
      // console.log('w1<w2')
      ret = 180+ret;
    }else{
      // console.log('w1>w2')
      ret = -ret;
    }
  }
  return ret ;
}
/**
* 坐标转换，百度地图坐标转换成腾讯地图坐标
* lng 腾讯经度（pointy）
* lat 腾讯纬度（pointx）
* 经度>纬度
*/
export function bMapToQQMap(lng, lat) {

  if (lng == null || lng == '' || lat == null || lat == '')
      return [lng, lat];

  var x_pi = 3.14159265358979324;
  var x = parseFloat(lng) - 0.0065;
  var y = parseFloat(lat) - 0.006;
  var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi);
  var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi);
  var lng = (z * Math.cos(theta)).toFixed(7);
  var lat = (z * Math.sin(theta)).toFixed(7);

  return [lng, lat];

}

/**
* 坐标转换，腾讯地图转换成百度地图坐标
* lng 腾讯经度（pointy）
* lat 腾讯纬度（pointx）
* 经度>纬度
*/

export function qqMapToBMap(lng, lat) {
  if (lng == null || lng == '' || lat == null || lat == '')
      return [lng, lat];

  var x_pi = 3.14159265358979324;
  var x = parseFloat(lng);
  var y = parseFloat(lat);
  var z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * x_pi);
  var theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * x_pi);
  var lng = (z * Math.cos(theta) + 0.0065).toFixed(5);
  var lat = (z * Math.sin(theta) + 0.006).toFixed(5);
  return [lng, lat];
}

/**
 * 防抖
 * @param {Function} func 要执行的回调函数 
 * @param {Number} wait 延时的时间
 * @param {Boolean} immediate 是否立即执行 
 * @return null  
 */
let timeout;
export function Debounce(func, wait=500, immediate = false) {
   // 清除定时器
	if (timeout !== null) clearTimeout(timeout);
	// 立即执行，此类情况一般用不到
	if (immediate) {
		var callNow = !timeout;
		timeout = setTimeout(function() {
			timeout = null;
		}, wait);
		if (callNow) typeof func === 'function' && func();
	} else {
		// 设置定时器，当最后一次操作后，timeout不会再被清除，所以在延时wait毫秒后执行func回调方法
		timeout = setTimeout(function() {
			typeof func === 'function' && func();
		}, wait);
	}
}

/**
 * 节流
 * @param {Function} func 要执行的回调函数 
 * @param {Number} wait 延时的时间
 * @param {Boolean} immediate 是否立即执行
 * @return null
 */
let timer, flag;
export function Throttle(func, wait = 300, immediate = true) {
	if (immediate) {
		if (!flag) {
			flag = true;
			// 如果是立即执行，则在wait毫秒内开始时执行
			typeof func === 'function' && func();
			timer = setTimeout(() => {
				flag = false;
			}, wait);
		}
	} else {
		if (!flag) {
			flag = true
			// 如果是非立即执行，则在wait毫秒内的结束处执行
			timer = setTimeout(() => {
				flag = false
				typeof func === 'function' && func();
			}, wait);
		}
	}
}

/**
* 获取不同环境
*/
export function getEnv() {
  var ua = navigator.userAgent.toLowerCase();
  var env = 'wx'
  if(ua.match(/MicroMessenger/i)=="micromessenger") {
    env = 'wx'
  }else if (ua.match(/AlipayClient/i) == "alipayclient") {
    env = 'alipay'
  }else if(ua.match(/iphone|ipad|ipod/)){
    env = 'ios'
  }else if(ua.match(/Android/i)){
    env = 'andriod'
  }
  return env
}

/**
 * 初始化微信环境
 */
export function initWxEnv() {
    return new Promise((resolve, reject) => {
        var htmm = document.getElementsByTagName("html")[0];
        var scri = document.createElement("script");
        scri.async = "async"
        scri.async = "/src/utils/jweixin-1.3.2.js"
        scri.onload = function () {
            console.log("script onloaded");
        }
        htmm.appendChild(scri);
        setTimeout(() => {
            resolve()
        }, 1000)
    })

}

/**
* 初始化支付宝环境
*/
export function initAlipayEnv() {
    return new Promise((resolve, reject) => {
      var htmm =document.getElementsByTagName("html")[0];
      var scri = document.createElement("script");
      scri.async="async"
      scri.src="https://appx/web-view.min.js"
      scri.onload=function(){console.log("script onloaded");}
      htmm.appendChild(scri);
      setTimeout(()=>{
        resolve()
      }, 1000)
    })
  }

/**
 * 初始化IOS和安卓环境参数
 */
export function initIosAndAndroidEnv(env, name, id, data) {
    if (env === 'ios') {
        window.webkit.messageHandlers.LiMaoJSNativeInterface.postMessage({
            "eventName": name,
            "eventId": id,
            "data": data
        });
    } else {
        let jsonStr = JSON.stringify({
            "eventName": name,
            "eventId": id,
            "data": data
        })
        window.LiMaoJSNativeInterface.androidReceive(jsonStr);
    }
}

//通过id获取数据
export function getIosAndAndroidData(env, list) {
    if (env === 'ios') {
        window.addEventListener('message', function (e) { // 监听 message 事件
            let data = e.data || {}
            list.forEach(item => {
                if (data.eventId == item.id) {
                    let timeData = data.data
                    item.callback(timeData)
                }
            })
        });
    } else {
        window.androidBack = function (data) {
            list.forEach(item => {
                if (data.eventId == item.id) {
                    let timeData = data.data
                    item.callback(timeData)
                }
            })
        }
    }
}
/**
 * 初始化IOS和安卓回调
 */
export function initIosAndAndroidCallBack(env, list) {

    if (env === 'ios') {
        window.addEventListener('message', function (e) { // 监听 message 事件
            let data = e.data || {}
            list.forEach(item => {
                if (data.eventName == item.name) {
                    let timeData = data.data
                    item.callback(timeData)
                }
            })
        });
    } else {
        window.androidBack = function (data) {
            list.forEach(item => {
                if (data.eventName == item.name) {
                    let timeData = data.data
                    item.callback(timeData)
                }
            })
        }
    }
}

/**
 * [通过参数名获取url中的参数值]
 * @param  {[string]} queryName [参数名]
 * @return {[string]}           [参数值]
 */
export function GetQueryValue(queryName) {
    var query = decodeURI(window.location.search.substring(1));
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == queryName) {
            return pair[1];
        }
    }
    return null;
}


//获取ios参数
export function getQueryString() {
    // 定义返回结果
    const result = {}
    // 获取url上的参数（使用decodeURIComponent对url参数进行解码）
    const search = decodeURIComponent(window.location.search);
    const tempArr = search !== '' ? search.substr(1).split('&') : [];
    tempArr.forEach((item) => {
        if (item) {
            // 将参数名和参数值拆分
            const itemArr = item.split('=');
            // 参数名作为key, 参数值为value
            result[itemArr[0]] = itemArr[1];
        }
    });
    return result;
}