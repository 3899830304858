//state
const state = {
    showLoading: false  //初始状态隐藏loading
}

//action
const actions = {

}

//mutations
const mutations = {
    //将常量用作函数名的语法
    SHOWLOADING: (state) => {
        state.showLoading = true;
    },
    HIDELOADING: (state) => {
        state.showLoading = false;
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}