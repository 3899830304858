import { createRouter, createWebHistory } from 'vue-router'

// views 指向 src/views
const routes = [
    {
        path: '/',
        component: () => import('../views/index'),
        meta: {
            roles: ['passenger']
        },
    }, {
        path: '/passenger',
        component: () => import('../views/pc/app'),
        meta: {
            title: '礼帽出行APP'
        }
    }, {
        path: '/pc_aggrement',
        component: () => import('../views/pc/aggrement'),
        meta: {
            title: '保险条款'
        }
    }, {
        path: '/pc_price',
        component: () => import('../views/pc/price'),
        meta: {
            title: '价格公示'
        }
    },{
        path: '/pc_about',
        component: () => import('../views/pc/about'),
        meta: {
            title: '关于我们'
        }
    },{
        path: '/pc_connect',
        component: () => import('../views/pc/connect'),
        meta: {
            title: '联系我们'
        }
    },
    {
        path: '/h5_aggrement',
        component: () => import('../views/h5/aggrement'),
        meta: {
            title: '保险条款'
        }
    }, {
        path: '/h5_price',
        component: () => import('../views/h5/price'),
        meta: {
            title: '价格公示'
        }
    }, {
        path: '/h5_about',
        component: () => import('../views/h5/about'),
        meta: {
            title: '关于我们'
        }
    }, {
        path: '/h5_connect',
        component: () => import('../views/h5/connect'),
        meta: {
            title: '联系我们'
        }
    },
    {
        path: '/registe',
        component: () => import('../views/h5/registe/index'),
        meta: {
            title: '优惠券领取-礼帽出行'
        }
    }, 
    {
        path: '/rule',
        component: () => import('../views/h5/registe/rule'),
        meta: {
            title: '活动规则'
        }
    }, {
        path: '/reg-success',
        component: () => import('../views/h5/registe/success'),
        meta: {
            title: '领取成功'
        }
    },
    {
        path: '/couponActive',
        component: () => import('../views/h5/couponActive/index'),
        meta: {
            title: '优惠券领取-礼帽出行'
        }
    },
    {
        path:'/giftCard',
        component: () => import('../views/h5/giftCard/bind.vue'),
        meta:{
            title:'礼品卡兑换'
        }
    },
    {
        path:'/giftCardRecord',
        component: () => import('../views/h5/giftCard/record.vue'),
        meta:{
            title:'绑卡记录'
        }
    },
    {
        path:'/item',
        component: () => import('../views/h5/operation/item.vue'),
        meta: {
            title:'test'
        }
    },
    {
        path:'/large',
        component: () => import('../views/h5/operation/large.vue'),
        meta: {
            title:'大件出行坐礼帽'
        }
    },
    {
        path:'/family',
        component: () => import('../views/h5/operation/family.vue'),
        meta: {
            title:'全家出行坐礼帽'
        }
    },
    {
        path:'/safe',
        component: () => import('../views/h5/operation/safe.vue'),
        meta: {
            title:'安全出行坐礼帽'
        }
    },
    {
        path:'/protect',
        component: () => import('../views/h5/operation/protect.vue'),
        meta: {
            title:'防护出行坐礼帽'
        }
    },
    {
        path:'/business',
        component: () => import('../views/h5/operation/business.vue'),
        meta: {
            title:'商务出行坐礼帽'
        }
    },
    {
        path:'/accompany',
        component: () => import('../views/h5/operation/accompany.vue'),
        meta: {
            title:'陪伴出行坐礼帽'
        }
    },
    {
        path:'/charterCar',
        component: () => import('../views/h5/operation/charterCar.vue'),
        meta: {
            title:'包车出行'
        }
    },
    {
        path: '/coupon-success',
        component: () => import('../views/h5/couponActive/success'),
        meta: {
            title: '领取成功-礼帽出行'
        }
    },
    {
        path: '/coupon-rule',
        component: () => import('../views/h5/couponActive/rule'),
        meta: {
            title: '活动规则'
        }
    },
    {
        path: '/coupon-error',
        component: () => import('../views/h5/couponActive/error'),
        meta: {
            title: '活动未开始'
        }
    },
    {
        path: '/coupon-statics',
        component: () => import('../views/h5/couponActive/statics'),
        meta: {
            title: '查看活动数据'
        }
    },
    {
        path: '/coupon-statics-detail',
        component: () => import('../views/h5/couponActive/staticsDetail'),
        meta: {
            title: '查看用户明细'
        }
    },
    {
        path: '/h5-enterprise',
        component: () => import('../views/h5/es/index'),
        meta: {
            title: '企业版H5'
        }
    },
    {
        path: '/lmcx-enterprise',
        component: () => import('../views/pc/es/index'),
        meta: {
            title: '企业版'
        }
    },
    {
        path: '/ad-noSecret',
        component: () => import('../views/h5/ad/noSecret'),
        meta: {
            title: '免密支付'
        }
    },
    {
        path: '/ad-enterprise',
        component: () => import('../views/h5/ad/enterprise'),
        meta: {
            title: '企业版'
        }
    },
    {
        path: '/ad-private',
        component: () => import('../views/h5/ad/private'),
        meta: {
            title: '私人定制'
        }
    },
    {
        path: '/arg-reg',
        component: () => import('../views/arg/reg'),
        meta: {
            title: '司机招募隐私政策'
        }
    },
]

export default createRouter({
    routes,
    history: createWebHistory()
});